import { Select } from 'components/Form/Select';
import { useTecimobDepartament } from 'hooks/fetch/useDepartament';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

export function DepartamentId() {
  const { control } = useFormContext();

  const { data: departaments, isLoading } = useTecimobDepartament();

  return (
    <InputGroup>
      <Select
        name="department_id"
        control={control}
        label="Departamento"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum conteúdo de atendimento disponível.',
          options: departaments ? departaments.map((departament) => ({ value: departament.id, label: departament.name })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}