import { InputGroup } from "../../styles/InputGroup";

import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router-dom";

import { Container, Content } from "./styles";

import { useAuth } from "../../hooks/useAuth";
import { Input } from "components/Form/Input";
import { Button } from "styles/Button";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { SignInCredentials } from "types/Auth";
import { handleFormErrors } from "utils/Form";
import { Form } from "styles/Form";
import { loginValidator } from "./validation";

const validationSchema = loginValidator();

export function Login() {
  const { login, qrCode } = useAuth();
  const history = useHistory();

  const { handleSubmit, setError, control, formState: { isSubmitting } } = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      password: '',
      remember: false,
      code: '',
    },
  });

  async function onSubmit({ email, password, remember, code }: SignInCredentials) {
    try {
      await login({ email, password, remember, code });
      history.replace('/');
    } catch (error) {
      handleFormErrors(error, setError);
    }
  }

  const handleDoneClick = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Content>
        <h1>Entrar</h1>

        <Form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
          {!qrCode && (
            <>
              <InputGroup>
                <Input
                  control={control}
                  type="email"
                  id="email"
                  label="Email"
                  placeholder="email@email.com"
                  name="email"
                  autoFocus
                  fullwidth
                />
              </InputGroup>

              <InputGroup>
                <Input
                  control={control}
                  type="password"
                  id="password"
                  label="Password"
                  placeholder="********"
                  name="password"
                  fullwidth
                />
              </InputGroup>

              <InputGroup>
                <Input
                  control={control}
                  type="text"
                  id="code"
                  label="Código 2FA"
                  placeholder="Digite o código 2FA"
                  name="code"
                  fullwidth
                />
              </InputGroup>

              <InputGroup>
                <Button type="submit" fullwidth disabled={isSubmitting}>
                  {isSubmitting ? 'Carregando...' : 'Entrar'}
                </Button>
              </InputGroup>
            </>
          )}
        </Form>

        {/* Exibir QR Code para ativação do 2FA, se necessário */}
        {qrCode && (
            <div>
              <h2>Ative a autenticação de dois fatores</h2>
              <p>Escaneie este QR Code no Google Authenticator:</p>
              <br></br>
              <div dangerouslySetInnerHTML={{ __html: qrCode }} />

              <InputGroup>
                <Button onClick={handleDoneClick} typeof="button" fullwidth>
                  Pronto
                </Button>
              </InputGroup>
            </div>
          )}

      </Content>
    </Container>
  )
}
