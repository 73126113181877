import * as Yup from 'yup';

export function templateMessageValidator() {
  return Yup.object().shape({
    customTemplate: Yup
      .string()
      .required('Por favor, informe um conteúdo válido para prosseguir.'),
      name_person: Yup
      .string()
      .required('Por favor, informe um nome da pessoa para prosseguir.'),
  });
}
