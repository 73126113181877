import { Select } from 'components/Form/Select';
import { usePlatform } from 'hooks/fetch/usePlatform';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

type PlatformIdProps = {
  name: string;
}

export function PlatformId({ name }: PlatformIdProps) {
  const { control } = useFormContext();

  const { data: platforms, isLoading } = usePlatform();

  return (
    <InputGroup>
      <Select
        name={name}
        control={control}
        label="Dispositivo"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum canal disponível.',
          options: platforms ? platforms.map((platform) => ({ value: platform.id, label: platform.title })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
