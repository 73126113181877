import { Select } from 'components/Form/Select';
import { usePlatform } from 'hooks/fetch/usePlatform';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

type NameProps = {
  name: string;
}

export function DevicesId({ name }: NameProps) {
  const { control } = useFormContext();

  const { data: attendanceContents, isLoading } = usePlatform();

  return (
    <InputGroup>
      <Select
        name={name}
        control={control}
        label="Dispositivo"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Não se aplica.',
          options: attendanceContents ? attendanceContents.map((attendanceContent) => ({ value: attendanceContent.id, label: attendanceContent.title })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
      />
    </InputGroup>
  );
}
