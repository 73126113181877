import Modal from "react-modal";
import { ModalTitle } from "styles/Modal";
import closeImg from '../../assets/close.svg';
import { Form } from "./components/Form";
import { TransferContactModalProps } from "./types";

export function TransferContactModal({ isOpen, onRequestClose, contact }: TransferContactModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content fixed-height"
      style={{ 
        content: { 
          width: '90vw',
          maxWidth: '800px',
          height: '100vh',
          maxHeight: '90vh',
          margin: 'auto',
          overflowY: 'auto',
          padding: '20px'
        } 
      }}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Transferir {contact.name ? `"${contact.name}"` : 'contato'}</ModalTitle>
        <Form onRequestClose={onRequestClose} contact={contact} />
      </div>
    </Modal>
  );
}
