import styled from "styled-components";

export const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const FormRowChannel = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  & > *:first-child {
    flex: 2;
  }

  & > *:last-child {
    flex: 1;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  
  /* Responsividade */
  @media (max-width: 768px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

export const InputWrapperHeader = styled.div`
  width: 100%;
  max-width: 450px;
  gap: 10px;
  display: flex;
  flex-direction: row;
`;