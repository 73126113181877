import { yupResolver } from "@hookform/resolvers/yup";
import { ArchiveContactData } from "components/ArchiveContactModal/types";
import { archiveContactValidator } from "components/ArchiveContactModal/validation";
import { useArchiveContact } from "hooks/mutations/useArchiveContact";
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Button } from "styles/Button";
import { Form as StyledForm } from 'styles/Form';
import { InputGroup } from "styles/InputGroup";
import * as Fields from '../../fields';
import { FormProps } from "./types";
import { FormRow, InputWrapper, InputWrapperHeader } from "styles/FormRow";
import { useEffect } from "react";

const validationSchema = archiveContactValidator();

export function Form({ onRequestClose, contact }: FormProps) {
  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      archive_without_register: false,
      send_satisfaction: true,
      remove_responsible: !!contact.responsibleId,
      remove_category: !!contact.categoryId,
      records: [{}],
    },
  });

  const { handleSubmit, control, reset, formState: { isSubmitting } } = form;
  const archiveWithoutRegister = useWatch({ name: 'archive_without_register', control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "records",
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [fields, append]);

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const archiveContactMutation = useArchiveContact();

  async function handleArchiveWithoutRegister(data: ArchiveContactData) {
    await archiveContactMutation.mutateAsync({
      contactId: contact.id,
      data: {
        ...data,
        remove_responsible: data.remove_responsible ?? true,
        remove_category: data.remove_category ?? true,
        send_satisfaction: data.send_satisfaction ?? true,
        lead_wrong: data.lead_wrong ?? false,
      },
    });
    handleRequestClose();
  }

  async function handleArchiveContact(data: ArchiveContactData) {
    if (data.archive_without_register) return handleArchiveWithoutRegister(data);

    await archiveContactMutation.mutateAsync({
      contactId: contact.id,
      data: {
        ...data,
        remove_responsible: data.remove_responsible ?? true,
        remove_category: data.remove_category ?? true,
      },
    });
    handleRequestClose();
  }

  return (
    <StyledForm onSubmit={handleSubmit(handleArchiveContact as SubmitHandler<FieldValues>)}>
      <div style={{ maxHeight: '800px', overflowY: 'auto', overscrollBehavior: 'contain', overflow: 'visible' }}>
        <FormProvider {...form}>
          {archiveWithoutRegister ? (
            <>
              <FormRow>
                <Fields.ArchiveWithoutRegister />
                <Fields.RemoveResponsible hasResponsible={!!contact.responsibleId} />
              </FormRow>
              <FormRow>
                <Fields.RemoveCategory hasCategory={!!contact.categoryId} />
                <Fields.SendSatisfaction />
              </FormRow>
              <FormRow>
                <Fields.LeadWrong />
              </FormRow>
            </>
          ) : (
            <>
              <FormRow style={{ flexDirection: 'column' }}>
                <InputWrapperHeader>
                  <Fields.SendSatisfaction />
                  <Fields.RemoveResponsible hasResponsible={!!contact.responsibleId} />
                  <Fields.RemoveCategory hasCategory={!!contact.categoryId} />
                </InputWrapperHeader>
              </FormRow>

              <FormRow style={{ flexDirection: 'column' }}>
                {fields.map((field, index) => (
                  <FormRow key={field.id}>
                    <Fields.DevicesId name={`records.${index}.platform_id`} />
                    <Fields.CategoryId name={`records.${index}.category_id`} contactCategoryId={contact.categoryId} />
                    <InputWrapper>
                      <Fields.AttendanceContentId name={`records.${index}.subject_id`} />
                    </InputWrapper>
                    <InputWrapper>
                      <Fields.ComplementId name={`records.${index}.complement_id`} />
                    </InputWrapper>
                    <Button
                      style={{
                        height: 40,
                        width: 40,
                        marginTop: 40,
                        alignSelf: 'center',
                      }}
                      onClick={() => append({})}
                      type="button"
                    >
                      +
                    </Button>
                    {fields.length > 1 && (
                      <Button
                        style={{
                          height: 40,
                          width: 40,
                          marginTop: 40,
                          alignSelf: 'center',
                        }}
                        onClick={() => remove(index)}
                        type="button"
                      >
                        -
                      </Button>
                    )}
                  </FormRow>
                ))}
              </FormRow>
              <Fields.Annotation />
            </>
          )}

          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting} accent>
              {isSubmitting ? 'Carregando...' : 'Arquivar contato'}
            </Button>
          </InputGroup>
        </FormProvider>
      </div>
    </StyledForm>
  );
}
