import { createContext, useEffect, useState } from "react";
import { getAuthorizationToken } from "../utils/Auth";
import { auth, authenticatedUser, leave } from "../services/auth";
import { AuthContextData, AuthProviderProps, SignInCredentials } from "../types/Auth";
import { User } from "types/User";

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User>({} as User);
  const [qrCode, setQrCode] = useState<string | null>(null);

  const isAuthenticated = Object.keys(user).length !== 0;

  useEffect(() => {
    const token = getAuthorizationToken();

    if (!token) {
      return setIsLoading(false);
    }

    async function getAuthenticatedUser() {
      try {
        const { user } = await authenticatedUser();
        setUser(user);
      } catch (error) {
        logout();
      } finally {
        setIsLoading(false);
      }
    }

    getAuthenticatedUser();
  }, []);

  async function login({ email, password, remember, code }: SignInCredentials & { code?: string }) {
    try {
      const { user, qr_code } = await auth({ email, password, remember, code });

      if (qr_code) {
        setQrCode(qr_code);
        return;
      }

      setIsLoading(false);
      setUser(user);
    } catch (error: unknown) {
      throw error;
    }
  }

  function logout() {
    leave();
    setUser({} as User);
  }

  return (
    <AuthContext.Provider value={{ login, logout, user, isLoading, isAuthenticated, qrCode }}>
      {children}
    </AuthContext.Provider>
  );
}
