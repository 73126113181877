import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Subjects } from "types/Subjects";
import { handleRequestError } from "utils/Request";

type SubjectsParams = {}

export async function fetchSubjects(params?: SubjectsParams): Promise<Subjects[]> {
  const { data } = await api.get('/tecimob/platforms', { params });
  return data;
}

export function usePlatform(params?: SubjectsParams) {
  const query = useQuery('devices', () => fetchSubjects(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os dispositivos. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
