import { useEffect } from "react";
import Swal from "sweetalert2";
import { useContacts } from "hooks/useContacts";
import { ContactsTable } from "components/ContactsTable";
import { ContactsProps } from "./types";
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router";
import { isEmpty } from "utils/Misc";
import { useSocket } from "hooks/useSocket";
import { api } from "services/api";
import { handleRequestError } from "utils/Request";
import { WithoutPermission } from "components/WithoutPermission";
import { useAuth } from "hooks/useAuth";
import { DEPARTMENT_COMMERCIAL_ID } from "constants/remote";

export function Contacts({ endpoint }: ContactsProps) {
  const { user } = useAuth();
  const match = useRouteMatch();
  const {
    currentEndpoint,
    setCurrentEndpoint,
    selectedContact,
    selectContact
  } = useContacts();

  const { channelPrivate } = useSocket();

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const path = generatePath(endpoint, { ...match.params });
    if (currentEndpoint === path) return;
    setCurrentEndpoint(path);
  }, [currentEndpoint, setCurrentEndpoint, endpoint, match.params]);

  useEffect(() => {
    if (!channelPrivate || isEmpty(channelPrivate)) return;

    async function selectUnarchivedContact(id: string) {
      try {
        const { data } = await api.get(`/contacts/${id}`);

        selectContact(data);
      } catch (error) {
        handleRequestError(error, 'Erro ao selecionar contato. Por favor, tente novamente!');
      }
    }

    channelPrivate.listen('.UnarchivedContact', async (e: any) => {
      if (!isEmpty(selectedContact) && e.data.contactId === selectedContact.id) return;

      const willSelect = await Swal.fire({
        title: 'Nova mensagem de contato arquivado',
        text: 'Um contato arquivado enviou uma mensagem. Deseja selecionar este contato para continuar a conversa?',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'Ignorar',
        confirmButtonText: 'Selecionar contato',
        reverseButtons: true,
        allowEnterKey: false,
      });

      if (!willSelect.isConfirmed) return false;

      selectUnarchivedContact(e.data.contactId);
    });
  }, [channelPrivate, selectedContact, selectContact, history, pathname]);

  if (user?.default_category_id === DEPARTMENT_COMMERCIAL_ID && (pathname === '/contatos/novos' || pathname === '/contatos/todos-novos'))  {
    return <WithoutPermission />;
  }

  return currentEndpoint
    ? <ContactsTable key={currentEndpoint} endpoint={currentEndpoint} />
    : null;
}
