import { isEmpty } from 'utils/Misc';

import { Chat } from 'components/Chat';
import { NoContactsSelected } from 'components/NoContactsSelected';
import { useContacts } from 'hooks/useContacts';
import { MessagesProvider } from 'contexts/MessagesContext';
import { useQuery } from 'hooks/useQuery';

export function ChatSection() {
  const { selectedContact } = useContacts();

  const { msg } = useQuery();

  return (
    <>
      {isEmpty(selectedContact) ? (
        <NoContactsSelected />
      ) : (
        <MessagesProvider perPage={!!msg ? 999999 : 70}>
          <Chat />
        </MessagesProvider>
      )}
    </>
  );
}
