import { Select } from 'components/Form/Select';
import { useTecimobCategories } from 'hooks/fetch/useTecimobCategories';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CategoryIdProps } from './types';
import { InputGroup } from 'styles/InputGroup';

export function CategoryId({ contactCategoryId, name }: CategoryIdProps) {
  const { control, setValue } = useFormContext();

  const { data: categories, isLoading } = useTecimobCategories();

  useEffect(() => {
    if (!categories?.length || !contactCategoryId) return;

    const defaultCategory = categories.find((category) => {
      return category.whatsapp_category_id === contactCategoryId;
    });

    if (defaultCategory) {
      setValue('category_id', defaultCategory.id);
    }
  }, [setValue, categories, contactCategoryId]);

  return (
    <InputGroup>
      <Select
        name={name}
        control={control}
        label="Categoria"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum categoria disponível.',
          options: categories ? categories.map((category) => ({ value: category.id, label: category.title })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
