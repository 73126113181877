import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { Complements } from "types/Complements";
import { handleRequestError } from "utils/Request";

type ComplementsParams = {}

export async function fetchComplements(params?: ComplementsParams): Promise<Complements[]> {
  const { data } = await api.get('/tecimob/complements', { params });
  return data;
}

export function useComplements(params?: ComplementsParams) {
  const query = useQuery('complements', () => fetchComplements(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar os complementos. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}
