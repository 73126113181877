import * as Yup from 'yup';

export function transferContactValidator() {
  return Yup.object().shape({
    records: Yup.array().of(
      Yup.object().shape({
        platform_id: Yup.string().required('Informe a plataforma.'),
        category_id: Yup.string().required('Informe o setor.'),
        subject_id: Yup.string().required('Informe o assunto.'),
        complement_id: Yup.string().nullable(), // Se for opcional
      })
    ),
    annotation: Yup.string().nullable(),
    whatsapp_annotation: Yup.string().nullable(),
    department_id: Yup.string().required('Informe o departamento.')
  });
}

