import Modal from "react-modal";
import { ModalTitle } from 'styles/Modal';
import closeImg from '../../assets/close.svg';
import { Form } from "./components/Form";
import { ArchiveContactModalProps } from "./types";

export function ArchiveContactModal({ contact, isOpen, onRequestClose }: ArchiveContactModalProps) {
  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    overlayClassName="react-modal-overlay"
    className="react-modal-content fixed-height"
    style={{ 
      content: { 
        width: '100vw',
        maxWidth: '1400px',
        margin: 'auto'
      } 
    }}
  >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar modal" loading="lazy" />
      </button>

      <div>
        <ModalTitle>Arquivar {contact.name ? `"${contact.name}"` : 'contato'}</ModalTitle>
        <Form onRequestClose={onRequestClose} contact={contact} />
      </div>
    </Modal>
  );
}
