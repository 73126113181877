import { useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "services/api";
import { TecimobCategory } from "types/TecimobCategory";
import { handleRequestError } from "utils/Request";

type TecimobCategoriesParams = {}

export async function fetchTecimobDepartaments(params?: TecimobCategoriesParams): Promise<TecimobCategory[]> {
  const { data } = await api.get('/tecimob/departments', { params });
  console.log('Data ', data)
  return data;
}

export function useTecimobDepartament(params?: TecimobCategoriesParams) {
  const query = useQuery('tecimobDepartament', () => fetchTecimobDepartaments(params));

  useEffect(() => {
    if (!query.isError) return;
    handleRequestError(query.error, 'Ocorreu um erro ao listar as departamentos. Por favor, tente novamente!');
  }, [query.isError, query.error]);

  return query;
}