import { Select } from 'components/Form/Select';
import { useComplements } from 'hooks/fetch/useComplement';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

type ComplementIdProps = {
  name: string;
}

export function ComplementId({ name }: ComplementIdProps) {
  const { control } = useFormContext();

  const { data: complements, isLoading } = useComplements();

  return (
    <InputGroup>
      <Select
        name={name}
        control={control}
        label="Complemento"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum canal disponível.',
          options: complements ? complements.map((complements) => ({ value: complements.id, label: complements.title })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
