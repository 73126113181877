import { Select } from 'components/Form/Select';
import { useAttendanceContents } from 'hooks/fetch/useAttendanceContents';
import { useFormContext } from 'react-hook-form';
import { InputGroup } from 'styles/InputGroup';

type AttendanceContentIdProps = {
  name: string;
}

export function AttendanceContentId({ name }: AttendanceContentIdProps) {
  const { control } = useFormContext();

  const { data: attendanceContents, isLoading } = useAttendanceContents();

  return (
    <InputGroup>
      <Select
        name={name}
        control={control}
        label="Assunto"
        placeholder="Selecione"
        fullwidth
        config={{
          noOptionsMessage: () => 'Nenhum conteúdo de atendimento disponível.',
          options: attendanceContents ? attendanceContents.map((attendanceContent) => ({ value: attendanceContent.id, label: attendanceContent.content })) : [],
          closeMenuOnSelect: true,
          isDisabled: isLoading,
        }}
        required
      />
    </InputGroup>
  );
}
