import * as Yup from 'yup';

export function archiveContactValidator() {
  return Yup.object().shape({
    archive_without_register: Yup.boolean(),
    remove_responsible: Yup.boolean(),
    remove_category: Yup.boolean(),
    send_satisfaction: Yup.boolean(),
    lead_wrong: Yup.boolean(),

    records: Yup.array().of(
      Yup.object().shape({
        platform_id: Yup.string().required('Informe a plataforma.'),
        category_id: Yup.string().required('Informe a categoria.'),
        subject_id: Yup.string().required('Informe o assunto.'),
        complement_id: Yup.string().nullable(),
      })
    ).min(1, 'É necessário pelo menos um registro.'),

    annotation: Yup.string(),
  });
}

