import { yupResolver } from '@hookform/resolvers/yup';
import { transferContactValidator } from 'components/TransferContactModal/validation';
import { FieldValues, FormProvider, SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { Button } from 'styles/Button';
import { Form as StyledForm } from 'styles/Form';
import { InputGroup } from 'styles/InputGroup';
import * as Fields from '../../fields';
import { FormProps } from './types';
import { useTransferContact } from 'hooks/mutations/useTransferContact';
import { TransferContactData } from 'components/TransferContactModal/types';

const validationSchema = transferContactValidator();

export function Form({ onRequestClose, contact }: FormProps) {
  const form = useForm<FieldValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      annotation: null,
      whatsapp_annotation: null,
      records: [{}],
    },
  });

  const { reset, handleSubmit, formState: { isSubmitting }, control } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'records',
  });

  function handleRequestClose() {
    reset();
    onRequestClose();
  }

  const transferContactMutation = useTransferContact();

  async function handleTransferContact(data: TransferContactData) {
    console.log('Errors ', data);
    await transferContactMutation.mutateAsync({ contactId: contact.id, data });
    handleRequestClose();
  }

  const { errors } = form.formState;
  console.log(errors);

  return (
    <StyledForm onSubmit={handleSubmit(handleTransferContact as SubmitHandler<FieldValues>)}>
      <FormProvider {...form}>
        <div style={{
          maxHeight: '80vh',
          overflowY: 'auto',
          paddingRight: 10,
          overflow: 'visible'
        }}>
          {fields.map((item, index) => (
            <div key={item.id} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              <div style={{ flex: 1 }}>
                <Fields.PlatformId name={`records.${index}.platform_id`} />
              </div>
              <div style={{ flex: 1 }}>
                <Fields.CategoryId name={`records.${index}.category_id`} contactCategoryId={contact.categoryId} />
              </div>
              <div style={{ flex: 1 }}>
                <Fields.AttendanceContentId name={`records.${index}.subject_id`} />
              </div>
              <div style={{ flex: 1 }}>
                <Fields.ComplementId name={`records.${index}.complement_id`} />
              </div>
              <Button
                type="button"
                onClick={() => append({})}
                style={{ height: 40, width: 40, marginTop: 40, alignSelf: 'center' }}
              >
                +
              </Button>
              {fields.length > 1 && (
                <Button
                  type="button"
                  onClick={() => remove(index)}
                  style={{ height: 40, width: 40, marginTop: 40, alignSelf: 'center' }}
                >
                  -
                </Button>
              )}
            </div>
          ))}

          <Fields.Annotation/>
          <Fields.WhatsAppAnnotation />

          <Fields.DepartamentId />

          <InputGroup>
            <Button type="submit" fullwidth disabled={isSubmitting}>
              {isSubmitting ? 'Carregando...' : 'Transferir contato'}
            </Button>
          </InputGroup>
        </div>
      </FormProvider>
    </StyledForm>
  );
}
